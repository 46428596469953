
            @import 'src/styles/mixins.scss';
        
.bg {
    // color: #fff;
    position: relative;
    // background-image: url(./qoutBg.svg);
    // background-repeat: no-repeat;
}
.carBg {
    min-height: 460px;

    @media screen and (min-width: 768px) {
        min-height: 369px;
    }
}
.highlight {
    color: #ff8500;
}

.title {
    font-size: 15px;
    line-height: 1.2;
    .highlight {
        font-size: 35px;
    }
    @media screen and (min-width: 769px) {
        font-size: 25px;
        line-height: 42px;
        .highlight {
            font-size: 54px;
        }
    }
}
.brief {
    color: #4e6e9c;
    font-size: 14px;
    line-height: 1.6;
    @media screen and (min-width: 900px) {
        font-size: 20px;
    }
}
.briefWrapper {
    width: inherit;
    padding-left: 25px;
    @media screen and (min-width: 1206px) {
        padding-left: 0;
    }
    @media screen and (min-width: 768px) {
        width: 45%;
    }
}
.center {
    max-width: 1206px;
    margin: 0 auto;
}
.briefWrapper {
    &:after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -60px;
        width: 117px;
        height: 92px;
        background: url(/assets/images/qout.svg) no-repeat;
    }
}
.bannerImage {
    position: absolute;
    right: 0;
    bottom: 35px;
    width: 343px;
    @media screen and (min-width: 768px) {
        width: 450px;
        top: 120px;
        bottom: inherit;
    }
    @media screen and (min-width: 1000px) {
        width: 500px;
        top: 105px;
    }
    @media screen and (min-width: 1370px) {
        width: 773px;
    }
}
